
import _ from "underscore";
import { Component, Vue } from "vue-property-decorator";
import * as CountryService from "@/api/helpers/Country";
import {
  deleteTemplate,
  findTemplates,
  Template as SMSTemplate,
  Payload,
} from "@/api/tharbad";
import DeletePopover from "@/components/DeletePopover.vue";
import AddTemplateDrawer from "@/components/sms/AddTemplateDrawer.vue";
import EditTemplateDrawer from "@/components/sms/EditTemplateDrawer.vue";
import { countryCode2FullNameFilter } from "@/filters";
import { CatalogItem } from "@/models/Catalog";
import Notify from "@/utils/notifications";

interface SortParams {
  columns: Record<string, unknown>;
  order: string | null;
  prop: string;
}
interface Params {
  limit: number;
  offset: number;
  query: {
    sender?: string;
    content?: string;
    "country-code"?: string;
    name?: string;
    sort?: string;
  };
}

@Component({
  components: {
    AddTemplateDrawer,
    DeletePopover,
    EditTemplateDrawer,
  },
  filters: { countryCode2FullNameFilter },
})
export default class SMSTemplatesView extends Vue {
  fullscreenLoading = true;
  showAddDrawer = false;
  showEditDrawer = false;
  currentPage = 1;
  pageNumberOfElementsOptions = [10, 25, 50, 100, 250, 500, 1000];
  defaultParams = {
    query: {
      name: undefined,
      sender: undefined,
      content: undefined,
      "country-code": undefined,
      sort: "-created-at",
    },
    limit: 10,
    offset: 0,
  };
  params: Params = _.clone(this.defaultParams);
  currentItem: SMSTemplate | null = null;
  items = {} as Payload<SMSTemplate>;
  countries: CatalogItem<string>[] = [];

  async deleteCard(id: number) {
    this.fullscreenLoading = true;
    try {
      const itemId = this.items.rows[id].id;
      await deleteTemplate(itemId);
      await this.getItems();
      Notify.successful(`Plantilla ${itemId} eliminada exitosamente.`);
    } catch (e) {
      Notify.gebServerError(e);
    }
    this.fullscreenLoading = false;
  }

  openAddDrawer() {
    this.showAddDrawer = true;
  }

  closeAddDrawer(value: boolean) {
    this.showAddDrawer = value;
  }

  closeEditDrawer(value: boolean) {
    this.showEditDrawer = value;
  }

  editTemplate(index: number) {
    this.currentItem = this.items.rows[index];
    this.showEditDrawer = true;
  }

  async sortTable(params: SortParams) {
    if (params.order === null) {
      this.params.query.sort = undefined;
    } else {
      this.params.query.sort = `${params.order === "descending" ? "-" : "+"}${
        params.prop
      }`;
    }
    await this.getItems();
  }

  async getItems(page = this.currentPage) {
    this.currentPage = page;
    this.fullscreenLoading = true;
    this.params.offset = (page - 1) * this.params.limit;
    this.items = await findTemplates({
      ...this.params.query,
      limit: this.params.limit,
      offset: this.params.offset,
    });
    this.fullscreenLoading = false;
  }

  created() {
    const index = "6.3";
    const title = "Plantillas de SMS";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "SMS",
        to: {
          name: "sms-campaign",
        },
      },
      {
        text: "Plantillas",
      },
    ]);
    Promise.all([
      CountryService.findCountryCodes().then(data => {
        this.countries = data.map(x => {
          return { id: x.countryCode, description: x.description };
        });
      }),
      this.getItems(),
    ]);
  }
}
